<template lang="pug">

main.about-page
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/overscans/games.jpg')",headline="Play to Win!")
	.container
		right-side-section(
			title="Billiards / Pool / Snooker"
			:img='require("@/assets/img/final_3d_images/low/img071.jpg")'
			description="Billiards / Pool / Snooker is a game of millimeters, where even the slightest twitch can mean the difference between being a hero or losing. Billiards / Pool / Snooker is also a game built upon foundation shots. Having just a few of these basic shots in your repertoire can not only help win games, but make you look good while doing it. Billiards / Pool / Snooker is the most stressful, calming, maddening, rewarding, frustrating and exciting of games."
		)

		left-side-section(
			title="Table Tennis"
			:img='require("@/assets/img/final_3d_images/low/img072.jpg")'
			description="Table Tennis is a very fun and enjoyable game. Most historians will agree that Table Tennis originated centuries ago in England. Table Tennis, also known as Ping-Pong, is a sport in which two or four players hit a lightweight ball back and forth across a table using small bats. Playing Table Tennis improves hand eye coordination, develop mental acuity, improve reflexesand keeps your brain sharp. Have you had knee surgery, back problems, tired of twisting your ankles? Try Table Tennis. It’sa great way to improve your leg, arm and core strength without overtaxing your joints."
		)
		
		right-side-section(
			title="Board Games"
			:img='require("@/assets/overscans/chess.jpg")'
			description="Play 50+ Board games"
		)

		left-side-section(
			title="Card Room"
			:img='require("@/assets/img/final_3d_images/low/img073.jpg")'
			description="The Card room with its spacious and captivating interiors will make you feel like a King playing taash. It can host upto 100 people."
		)

</template>


<script>
import RightSideSection from '../components/XRightSideSection.vue'
import LeftSideSection from '../components/XLeftSideSection.vue'
import DisplayHeader from '../components/DisplayHeader.vue'

export default {
	components: {
    RightSideSection,
	DisplayHeader,
    LeftSideSection
  },
}
</script>

<style>
</style>